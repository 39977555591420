
@media only screen and (max-width: 800px) {
    .static-menu-desktop button {
        padding-left: 0.5em;
        padding-right: 0.5em;
        font-size: 0.8em;
        hyphens: auto;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        word-break: break-all;
    }
}
