.card {
    padding-top: 1.2em !important;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.card .price {
    font-size: 1.3em;
}

.card-link {
    text-decoration: none;
    color: black;
}

.card .image {
    /* width: 180px;
    height: 180px; */
    max-width: 180px;
    max-height: 180px;
    padding: 0em;
    background-color: white;
}

.card .image img {
    max-height: 180px;
    padding: 0em;
}

.card-title {
    min-height: 2em;
}

.card a {
    text-decoration: none;
    color: black;
}

.priceRange {
    padding-left: 0.25em;
}

.price {
    font-weight: bold;
}

.dollars {
    display: inline;
}

.cents {
    display: inline;
    vertical-align: top;
    font-size: 0.7em;
}

.card .MuiButtonGroup-root {
    margin-top:auto;
}

.group-item {
    border-width: 3px;
    border-style: solid;
    border-color: darkblue;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
}

.group-item .card {
    min-height: 310px;
    background-color: lightblue;
}

.group-item .image {
    overflow: hidden;
}

.group-item img {
    border-width: 2px;
    border-style: solid;
    border-color: darkblue;
}

.group-header {
    background-color: darkblue;
    color: white;
    font-weight: bold;
    padding-left: 1em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

#inventory-controls {
    display: flex;
    gap: 2em;
}