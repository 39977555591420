
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Quicksand', 'Roboto', sans-serif;
  background-color: steelblue;
  min-height: 100vh;
  /* background-color: black;
  color: darkgray; */
}

#root {
  min-height: 100vh;
}

span.alert {
  color: darkred;
  font-weight: bold;
}

span.warn {
  color: palevioletred;
  font-weight: bold;
}


#main {
  display: flex;
  flex-direction: column;
}

#top {
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  width: 80%;
  align-self: center;
}


#left-column {
  min-width: 10em;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#banner {
  width: 100%;
}

#menu {
  border-radius: 10px;
  border-style: solid;
  border-color: #cceecc;
  border-width: 1px;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #003300;
  color: #eee;
}

#menu ul {
  display: flex;
  padding-left: 0;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}
#menu ul li {
  display: block;
  flex: 0 1 0;
  min-width: max-content;
  margin-right: 1em;
  border-right-style: solid;
  border-right-color: #cceecc;
  border-right-width: 1px;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

#menu ul li a {
  text-decoration: none;
  color: #ccc;
  font-weight: bold;
}


#intro {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-left: 1em;
  padding-right: 1em;
  align-self: center;
}

.phone {
  color: darkred;
  font-weight: bold;
}

.covid-rules {
  text-align: center;
}

#home-contact {
  text-align: center;
}

#home-contact div {
  margin: 0.2em;
}

.centred {
  text-align: center;
}

.disabled {
  opacity: 0.5;
}

.buttons {
  flex-grow: 1;
}

.order-options {
  font-size: 0.75em;
}

.gold {
  color: gold;
  -webkit-text-fill-color: gold;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.legal-header {
  font-size: 1.2em;
  font-weight: bold;
}

.legal-section {
  margin-top: 1em;
}

.really-small-text {
  font-size: 0.5em;;
}

#year-field div {
  width: 3em;
}

#legal-main {
  margin: 1em;
  padding-left: 1em;
  padding-right: 1em;
}


.ags-main {
  width: 100%;
  display: flex;
}

.ags-body {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 10%;
  margin-right: 10%;
  width: 100%;
}

.ags-body > .MuiPaper-root {
  margin: 1em;
  padding: 1em;
}

.green {
  color: darkgreen;
  font-weight: bold;
}

#namesearch {
  width: 400px;
}

#namesearch-label {
  margin-top: 0.4em;
}

.itemid {
  font-size: 0.7em;
  color: gray;
}


td.state_late {
  background-color: darkred;
  color: white;
  font-weight: bold;
}

td.state_late a {
  background-color: darkred;
  color: white;
  font-weight: bold;
}

td.state_today {
  color: darkred;
}

td.state_tomorrow {
  color: darkorange;
}

td.state_soon {
  color: darkgreen;
}

td.state_incomplete {
  color: lightgray;
}

div.state_late {
  padding-left: 1em;
}

.income-weekly-summary {
  height: 200px;
}

.error-text {
  color: darkred;
  font-weight: bold;
  padding: 1em;
  border-style: solid;
  border-width: 3px;
  border-color: darkred;
  background-color: lightpink;
}



/* --------------------------- */
.inventory-page {
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.inventory-page > div {
  margin-top: 1em;
  padding: 1em;
}

.inventory-page .count-input {
  width: 5em;
  min-width: 5em;
  display: inline-flex;
}

.inventory-page .count-input svg {
  width: 0.5em;
  padding-top: 0em;
  padding-bottom: 0em;
}


.inventory-page .count-input input {
  font-size: 0.8em;
  height: 1em;
  padding: 1em;
}

.inventory-page .count-input .count-input-buttons {
  height: 1em;
}
.inventory-page .count-input .count-input-buttons button {
  padding-top: 0em;
  padding-bottom: 0em;
}

.inventory-page #edit-name {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}


.inventory-page #edit-cost {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  width: 3.5em;
}

.inventory-page th {
  padding: 0.2em
}

.inventory-page td {
  padding: 0.2em
}


.inventory-page table {
  min-width: 60em;
}
