#header {
    flex-grow: 0;
}

#header .MuiAppBar-colorDefault {
    background-color: steelblue;
}

#header .MuiTab-textColorPrimary {
    color: darkslateblue;
}

.App .MuiAppBar-colorPrimary {
    background-color: darkslateblue;
}
    
/* ========================================================================================= */
/* Header logo, which has a fixed size and position, but is only visible when not scrolled   */
/* ========================================================================================= */
.header-logo {
    background-color: white;
    position: absolute;
    top: 0;
    left: 3px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border-color: darkgreen;
    border-width: 2px;
    border-style: solid;
    z-index: 999;
}

.header-logo img {
    position: relative;
    left: 2.3px;
    top: 2.3px;
    height: 96px;
}

/* ========================================================================================= */
/*                                                                                           */
/* ========================================================================================= */
.header-grow {
    flex-grow: 1;
}

.header-title {
    width: auto;
    white-space: nowrap;
}

.business-title > div {
    margin-top: 0em;
    margin-bottom: 0em;
}

.business-name {
    font-family: 'Hobo';
    margin-bottom: 0.1em;
    padding-bottom: 0.1em;
}

.business-subname {
    margin-top: 0em;
    padding-top: 0em;
    font-family: 'Hobo';
    font-size: 1em;
    text-align: right;
    font-size: 1.2em;
}

.business-subname > a {
    font-size: 1em;
}


.business-title {
    text-decoration: none;
    color: #fff;
    font-size: 1.7em;
}

.business-title img {
    float: left;
    padding-right: 0.5em;
    height: 40px;
}

.header-search {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    max-width: 100%;
    margin-left: 1em;
    flex-grow: 5;
}

.header-search-icon {
    padding-left: 0em;
    padding-right: 0em;
    margin-left: 0em;
    margin-right: 0em;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-section-desktop {
    display: flex;
    flex-direction: row;
}

.header-section-mobile {
    display: none;
}

#header div.hamburger {
    margin-left: 90px;
}

.header-input-root {
    color: inherit;
    display: flex;
}

.header-input-input {
    width: 90%;
    background-color: silver;
    padding-left: 0.5em;
}

@media only screen and (min-width: 1025px) {
    
}

#store-selector {
    color: white;
}