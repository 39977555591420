.overlay {
    z-index: 1090;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: black;
    opacity: 0.5;
}

.flex-div {
    display: flex;
}

.flex-spacing button {
    margin-right: 1em;
}

.id-image {
    width: 400px;
}

.id-image img {
    width: 100%;
}

.id-text-content {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
}

.id-text-content > p {
    margin-bottom: 1em;
}

.id-text-content > div {
    margin-bottom: 1em;
}

.item-edit {
    margin-top: 1em;
    z-index: 1100;
}

.item-editor-fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.item-editor-fields > div {
    /* min-width: 50ch; */
    margin-right: 2em;
    margin-top: 1em;
    height: auto;
}

.item-editor-text-fields {
    min-width: 40ch;
}

.item-editor-text-fields > div{
    min-width: 40ch;
    margin-top: 1em;
}

.edit-textarea {
    margin-top: 2em;
    width: 100%;
}

.edit-textarea > div {
    width: 100%;
}

.hidden {
    opacity: 0.25;
}


.dctf-label {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);
}

.dctf-text {
    height: 1em;
    padding-bottom: 0.25em;
}

.dctf-text a {
    text-decoration: none;
    color: #0645AD;
}

.dctf-text button {
    padding: 0;
    margin-right: 0.5em;
}

.dctf-disabled-text {
    height: 1em;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 0.25em;
}

.dctf-input {
}

.dctf-input button {
    padding: 0;
    margin-right: 0.5em;
}


.dctf-textfield {
    display: inline;
}

.hide {
    display: none;
}

.hlayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.editor-card {
    margin-top: 1em;
}

.group > .group-header {
    padding-bottom: 0em;
}

.editor-card > .content {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
}

.editor-card > .content > .MuiFormControl-root {
    margin-right: 1em;
}

.editor-card span.MuiCardHeader-title {
    font-size: 1.2em;
    color: slateblue;
}

.editor-card span.MuiCardHeader-subheader {
    font-size: 1.0em;
    color: slateblue;
}

.card-content-full-width div {
    width: 100%;
    min-width: 100%;
}

.close-icon {
    width: 2em;
    height: 2em;
    padding: 0;
    margin: 0;
}

.file-input {
    margin-top: 1em;
    margin-bottom: 1em;
}

.reset-counter {
    background-color: lightpink;
}