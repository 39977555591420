@font-face {font-family: "Gothic Caps";
    src: url("../fonts/gothic/4ae4ad988c7db962666b3f89febd9fb1.eot"); /* IE9*/
    src: url("../fonts/gothic/4ae4ad988c7db962666b3f89febd9fb1.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/gothic/4ae4ad988c7db962666b3f89febd9fb1.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/gothic/4ae4ad988c7db962666b3f89febd9fb1.woff") format("woff"), /* chrome、firefox */
    url("../fonts/gothic/4ae4ad988c7db962666b3f89febd9fb1.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/gothic/4ae4ad988c7db962666b3f89febd9fb1.svg#Gothic Caps") format("svg"); /* iOS 4.1- */
  }
  
  @font-face {font-family: "Old English Text MT";
    src: url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.eot"); /* IE9*/
    src: url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.woff") format("woff"), /* chrome、firefox */
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.svg#Old English Text MT") format("svg"); /* iOS 4.1- */
  }
  
  @font-face {font-family: "Old English Text MT";
    src: url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.eot"); /* IE9*/
    src: url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.woff") format("woff"), /* chrome、firefox */
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/old-english/f3258385782c4c96aa24fe8b5d5f9782.svg#Old English Text MT") format("svg"); /* iOS 4.1- */
  }

  @font-face {font-family: "Sailor";
    src: url("../fonts/sailor/SAILORORIGINALRegular.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/sailor/SAILORORIGINALRegular.woff") format("woff"), /* chrome、firefox */
    url("../fonts/sailor/SAILORORIGINALRegular.ttf") format("truetype") /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  }
  
  @font-face {
    font-family: 'Hobo';
    src:url('../fonts/hobo/Hobo.ttf.woff') format('woff'),
        url('../fonts/hobo/Hobo.ttf.svg#Hobo') format('svg'),
        url('../fonts/hobo/Hobo.ttf.eot'),
        url('../fonts/hobo/Hobo.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}
