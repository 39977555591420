@media only screen and (max-width: 1024px) {
    #id-main {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .id-title {
        font-size: 1.4em;
        font-weight: bold;
        margin-bottom: 1em;
    }
    
    .id-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
