ul {
    padding-left: 1em;
}

.store-parent {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 2em;
}

.store-main {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* For some reason min-width was reporting as > 5000px; WTF! */
    min-width: 250px;
    padding-top: 1em;
    align-content: flex-start;
    background-color: white;
    padding-bottom: 1em;
    border-radius: 25px;
    margin-left: 4%;
    margin-right: 4%;
}

.store-main .MuiTab-root {
    min-width: 0;
}

.store-main > header {
    width: 99%;
}

.brands {
  margin-top: 1em;
  margin-bottom: 1em;
}

.brand {
    display: flex;
    flex-direction: row;
    font-size: 1.2em;
    width: 120px;
    padding: 10px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.brand > #brand-content {
    padding: 0px;
}

.brand img {
    width: 100%;
    margin: 0;
    padding: 0;
}

.brand div {
    margin: 0;
    padding: 0;
    align-self: center;
}

.brand-name {
    font-size: 0.9em;
    color: slateblue;
}

.department-box {
}

.department-header {
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 0.5em;
    color: slateblue;
}

ul > div.dept {
    margin-top: 0em;
    margin-bottom: 0em;
    padding-top: 0em;
    padding-bottom: 0em;
}

.item-name {
    font-size: 1.1em;
}

/** Material UI Grid is doing some crazy, and it causes scrollbars where
 * I don't want them
 */
/* .store-main > .items {
    width: 100%;
} */

.beta {
    width: 80%;
    align-self: center;
}

.beta .superscript {
    display: inline;
    text-transform:capitalize;
    font-variant:small-caps;
    font-weight:bold;
    color: darkred;
    vertical-align: super;
    font-size: smaller;
}

.beta .title {
    margin-bottom: 1em;
}

.beta div {
    margin-bottom: 1em;
}

.beta .emphasis {
    color: darkred;
    font-style: italic;
    font-weight: bold;
}

.prominent {
    font-size: 1.4em;
}

.invoice-iframe {
    width: 600px;
    height: 600px;
}

.free-shipping {
    font-size: 0.8em;
    padding-left: 1em;
}

.shipping-info {
    display: inline-block;
    vertical-align: middle;
}


.shipping-descr .right button {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.shipping-estimator-parent {
    margin-top: 2em;
    margin-left: 10%;
    margin-right: 10%;
}

.delivery-description {
    margin-left: 0em;
    padding-left: 0em;
    width: 100%;
}

.delivery-description span {
    color: #0000EE;
}

.delivery-description > div > div:first-of-type {
    margin-bottom: 1em;
}

.delivery-date-selector {
    margin-top: 1em;
}

.delivery-notes div {
    width: 98%;
}

.delivery-notes textarea {
    width: 100%;
}

.masked {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: wait;
    z-index: 9999;
    background-color: black;
    opacity: 0.5;
    padding-top: 20%;
    padding-right: 20%;
    padding-left: 20%;
}

.masked div {
    height: 1em;
}

.item-link a {
    text-decoration: none;
    color: #0645AD;
}

.invisible {
    display: none;
}

.barcodes {
    display: flex;
    margin-top: 1em;
}

.barcodes .barcode {
    width: 230px;
    height: 100px;
    display: flex;
    margin-bottom: 0.5em;
}

.barcodes .barcode div {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.barcodes .barcode-wrapper a {
    text-decoration: none !important;
    color: #0000EE;
}

.barcodes .barcode div.barcode-brand {
    margin: 0;
    padding: 0;
    text-align: center;
}

.barcodes .barcode div.barcode-name {
    margin: 0;
    padding: 0;
    text-align: center;
}

.barcode svg {
    padding: 0;
    margin: 0;
    width: 200px;
    height: 50px;
}

.out-of-stock-banner {
    background-color: darkred;
    color: white;
    font-weight: bold;
    position: absolute;
    left: -1em;
    right: -1em;
    top: 5em;
    text-align: center;
    rotate: -10deg;
    padding: 0.2em;
}

.discontinued-banner {
    background-color: darkblue;
    color: white;
    font-weight: bold;
    position: absolute;
    left: -1em;
    right: -1em;
    top: 5em;
    text-align: center;
    rotate: -10deg;
    padding: 0.2em;
}

.special-order-banner {
    position: relative;
    left: -2em;
    width: 120%;
    background-color: darkgreen;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 0em;
    margin: 0em;
    font-size: 0.8em;
}

/** Override whatever Sirv does, cause it is not playing nice with my cards */
/* img.Sirv:not([width]):not([height]) {
    width: unset !important;
    max-width: 180px;
    max-height: 180px;
} */

img.Sirv {
    width: 100%;
    height: 100%;
}
img.Sirv.image-fit,
img.Sirv.image-fill {
    width: 100%;
    height: 100%;
}

img.Sirv.image-fit {
    object-fit: contain;
}

img.Sirv.image-fill {
    object-fit: cover;
}

.stock-count {
    font-size: 0.7em;
}

.space-above {
    margin-top: 1em;
}

.discontinued-text {
    font-size: 1.2em;
}

.editor-cards {
    display: flex;
    flex-direction: column;
}

.editor-cards > div {
    margin-bottom: 2em;
}

.thumbnail {
    max-height: 64px;
    height: 64px;
    max-width: 64px;
    width: 64px;
    overflow: none;
    display: block;
}

.variant-table {
    width: 100%;
}

.variant-subtable td {
    width: 100%;
}

.variant-subtable {
    width: 100%;
}

.variant-subtable tr {
    border-style: none;
}

.variant-table td {
    margin: 0.24em;
    padding: 0.24em;
}

.variant-table .narrow-cell {
    width: 1em;
}

.variant-table .wide-cell {
    width: 50%;
}

.variant-table .thumbnail-cell {
    width: 80px;
}

.variant-table .price-cell {
    width: 6em;
}

.variant-table .count-input {
    width: 6em;
    min-width: 6em;
    display: inline-flex;
}

.variant-table fieldset {
    width: 2em;
    max-width: 2em;
    min-width: 2em;
}
.variant-table label {
    width: 4em;
    max-width: 4em;
    min-width: 4em;
}

.variant-checkbox {
    width: 10em;
    display: inline-block;
}

.variant-textarea {
    width: 100%
}

.variant-textarea div {
    width: 100%
}


.variant-textarea label {
    width: 100%
}

button.add-variant {
    margin-left: 2em;
}

.markdown-field {
    height: auto;
}

.markdown-field .dctf-text {
    height: auto;
}

.markdown-field .dctf-textfield {
    width: 100%;
}

.markdown-field fieldset {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.fast-shipping-description {
    margin-bottom: 1em;
}

.fast-shipping-description span {
    font-weight: bold;
    font-style: italic;
}

.bottom-right {
    display: block;
    position: absolute;
    right: 0px;
    left: -0.2em;
    top: -0.4em;
    height: 1.6em;
    background-color: lightgray;
    background-image: linear-gradient(lightgray, white);
}

.bottom-right button {
    justify-content: left;
}

.bottom-right.special-order {
    background-color: skyblue;
    background-image: linear-gradient(skyblue, white);
}

.bottom-right.fast-shipping {
    background-color: steelblue;
    background-image: linear-gradient(steelblue, white); 
}

.bottom-right > button {
    color: gray;
}

.bottom-right > button > span {
    color: gray;
}

.bottom-right.fast-shipping > button {
    color: slateblue;
}

.bottom-right.fast-shipping > button > span {
    color: slateblue;
}

.margin-top {
    margin-top: 1em;
}

.inline {
    display: inline;
}

.fast-shipping-qualification {
    font-size: 0.9em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.cart-total .fast-shipping-qualification > button,
.fast-shipping-qualification > button {
    float: left;
    margin: 0.2em;
    padding: 0em;
    margin-top: 0em;
    margin-right: 0em;
    padding-right: 0em;
    min-width: 32px;
}

.fast-shipping-qualification > button > span {
    color: seagreen;
}

div.row {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
}

div.row > div {
    margin-right: 1em;
}

.noPadding {
    padding: 0px;
    max-width: 80px;
}