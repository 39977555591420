
@media only screen and (max-width: 640px) {
    .static-menu-desktop {
        display: none;
    }

    .static-menu-mobile {
        display: block;
        height: 58px;
        padding-left: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .static-menu-mobile button {
        margin-top: 0.2em;
    }
}
