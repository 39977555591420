#id-main {
    position: fixed;
    left: 5%;
    right: 5%;
    top: 4.5em;
    bottom: 10%;
    padding: 1em;
    background-color: steelblue;
    z-index: 1100;
    border-style: ridge;
    border-width: 3px;
    border-color: slateblue;
    filter: drop-shadow(8px 8px 5px slateblue);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
}

#id-main > .header-bar {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2em;
    background-color: steelblue;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    border-bottom: 2px ridge slateblue;
    padding-left: 1em;
}

.spacer {
    flex-grow: 10;
}

#id-main > .dialog-body {
    position: fixed;
    overflow: auto;
    top: 2.2em;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fafafa;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom-left-radius: 25px;
}

#id-main .masked {
    top: 2em;
}

.id-content {
    display: flex;
    flex-direction: row;
}

.id-content > div {
    display: flex;
    flex-basis: 100%;
    width: 100%;
    justify-content: center;
}

.id-content .image img {
    margin-top: 0em;
    padding-top: 0em;
}
