@media only screen and (min-width: 481px) and (max-width: 640px) {
    .header-section-desktop {
        display: none;
    }

    .header-search-desktop {
        display: none;
    }
    
    #header div.hamburger {
        visibility: hidden;
    }

    .header-section-mobile {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    
    .header-section-mobile {
        width: 100%;
    }

    .header-toolbar {
        padding-right: 0em !important;
    }

    .header-section-mobile > button {
        padding-right: 0em;
        padding-left: 0.5em;
    }
    
}
