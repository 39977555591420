
#header .menu {
    padding-left: 60px;
}

#buffer {
    display: none;
}

#buffer.scrolled {
    display: block;
    width: 100%;
    height: 144px;
}

#header.scrolled .menu {
    padding-left: 20px;
}

#header .MuiTab-root {
    min-width: 0;
}

#header.scrolled .MuiTab-root {
    margin-left: 0px;
}


#menu-dropdown {
    display: none;
    position: absolute;
    background-color: steelblue;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.5em;
    left: 10px;
    top: 112px;
    z-index: 222;
    text-transform: uppercase;
    font-size: 0.875rem;
    text-decoration: none;
    font-weight: 500;
}

.menu-dropdown-link {
    text-decoration: none;
    color: slateblue;
    cursor: pointer;
    width: 100%;
}

.menu-dropdown-link:hover {
    background-color: slategray;
}

.menu header {
    display: flex;
    flex-direction: row;
}

.menu header {
    box-shadow: none;
    padding-left: 2em;
}

.menu img {
    /* width: 1em; */
    margin-left: 1em;
    height: 1.8em;
    margin-top: 0.1em;
    display: none;
}

#header .MuiTab-root {
    text-transform: capitalize;
    font-size: 1em;
    font-weight: normal;
}

#header.scrolled .menu img {
    display: block;
}

#header.scrolled > .header-grow {
    display: none;
}

#header.scrolled > .menu {
    max-height: 2em;
}

#header.scrolled > .menu > header {
    max-height: 2em;
    box-shadow: none;
}

#header.scrolled > .menu button {
    margin-top: 0.5em;
    padding-top: 0em;
    min-height: 1em;
}

#header.scrolled > .menu button span {
    margin-top: 0em;
    padding-top: 0em;
}

#menu-dropdown {
    /* box-shadow: 2px 2px #7fac7f; */
    box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
    text-transform: capitalize;
    font-size: 1em;
}

#header.scrolled #menu-dropdown {
    top: 2.2em;
}


.menu {
    margin-top: 2px;
}

/* .menu button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    border-left-style: solid;
    border-left-color: slategray;
    border-left-width: 2px;

    border-top-style: solid;
    border-top-color: slategray;
    border-top-width: 2px;

    border-right-style: solid;
    border-right-color: slategray;
    border-right-width: 2px;
    margin-left: 1px;
}

.menu button.Mui-selected {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    background-color: white;

    border-left-style: solid;
    border-left-color: slateblue;
    border-left-width: 2px;

    border-top-style: solid;
    border-top-color: slateblue;
    border-top-width: 2px;

    border-right-style: solid;
    border-right-color: slateblue;
    border-right-width: 2px;
} */