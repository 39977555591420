@media only screen and (max-width: 480px) {
    .category-button {
        flex-basis: 100%;
        justify-content: center;
        align-items: stretch;
        display: flex;
    }
    
    .category-icon {
        display: flex;
        flex-grow: 2;
        justify-content: center;
        align-content: center;
    }
}
