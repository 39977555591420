

#header.scrolled > .static-menu-desktop {
    background-color: steelblue;
}

#header.scrolled {
    background-color: steelblue;
    height: 72px;
}

#header.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 72px;
    max-height: 72px;
    z-index: 1000;
}

.static-menu-desktop {
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    font-family: "Hobo";
    height: 58px;
}

.static-menu-desktop button {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    color: slateblue;
    font-family: "Hobo"

}

.static-menu-mobile {
    display: none;
}
