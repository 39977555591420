
.category-panel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    padding: 1em;
    justify-content: center;
}

.category-button {
    margin-right: 1em;
    margin-bottom: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    padding: 1em;
    border-radius: 4px;
    min-width: 200px;
    min-height: 180px;
    position: relative;
    cursor: pointer;
    box-shadow: 2px 2px #ccc;
}

.category-button > label {
    position: absolute;
    left: 10px;
    top: -0.7em;
    background-color: white;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.category-icon svg {
    font-size: 4em;
}

.category-icon .image {
    max-width: 180px;
    max-height: 180px;
    padding: 0em;
}

.infobar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    width: 100%;
}

.shipping-notice {
    text-align: right;
    margin-right: 2em;
    border-width: 2px;
    border-style: solid;
    border-color: darkred;
    border-radius: 1em;
    background-color: lightyellow;
    color: darkred;
    padding-left: 0.5em;
    padding-right: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.1em;
    font-size: 0.9em;
    font-weight: bold;
    display: flex;
    cursor: pointer;
}

.infobar button {
    font-size: 0.9em;
    margin-top: 0em;
    padding-top: 0em;
    margin-right: 1em;
    padding-bottom: 0em;
}

.breadcrumb {
    font-size: 0.8em;
    margin-top: 0.2em;
    margin-bottom: 1em;
}

.breadcrumb a {
    text-decoration: none;
    color: #0645AD;
}

.shipping-notice svg {
    font-size: 1.2em;
    top: 1em;
}

.clear-bottom {
    margin-bottom: 1em !important;
}