#category-placement {
    display: none;
    flex-direction: column;
    width: 330px;
    min-width: 330px;
}

#category-drawer {
    background-color: lightsteelblue;
    padding-left: 1em;
    padding-right: 1em;
    border-right-color: gray;
    border-right-style: ridge;
    border-right-width: 3px;
    position: fixed;
    bottom: 0px;
    top: 115px;
    width: 300px;
    min-width: 300px;
}

#category-drawer-header {
    display: flex;
    justify-content: flex-end;
}

.categories {
    list-style-type: none;
    padding-top: 1em;
    position: fixed;
    overflow: auto;
    bottom: 40px;
    top: 160px;
    left: 0px;
    padding-left: 1em;
    width: 310px;
}

.categories .groups {
    margin-top: 0em;
}

.categories a {
    color: royalblue;
    text-decoration: none;
}

.categories a:hover {
    color: darkblue;
}

.selected-category {
    font-weight: bold;
}

/* HACK: Disable the tab selection indicator */
.MuiTabs-indicator {
    display: none;  
}

.position-fixed {
    position: fixed;
    top: 5px;
    width: inherit;
}

button.tree-icon {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.8em;
}

button.tree-icon svg {
    font-size: 1.5em;
}

div > button.tree-icon {
    visibility: hidden;
}

div:hover > button.tree-icon {
    visibility: visible;
}

.category-widget > button {
    width: 1em;
    height: 1em;
    padding: 0em;
    margin-left: 1em;
}

#zounds {
    height: 100px;
    width: 100px;
    background-color: red;
}

.delivery-button button {
    display: inline-block;
    max-width: 10em;
}