@media only screen and (min-width: 641px) and (max-width: 1024px) {
    .header-section-desktop {
        display: none;
    }

    .header-section-mobile {
        display: flex;
        justify-content: flex-end;
    }

    .header-search-desktop {
        margin-right: 0em;
    }

    .header-toolbar {
        padding-right: 0em !important;
    }

    .header-section-mobile > button {
        padding-right: 0em;
        padding-left: 0.5em;
    }


}
