
#departments {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.department {
    text-decoration: none;
    border-style: solid;
    border-color: #cceecc;
    border-width: 1px;
    margin: 1em;
    padding: 0px;
    border-radius: 10px;
    flex: 1 0 0px;
    min-height: 300px;
    height: 400px;
    max-height: 400px;
    min-width: 150px;
}
