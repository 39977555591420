#footer {
    flex-grow: 0;
    margin-top: 1em;
}

#footer header {
    display: flex;
    background-color: steelblue;
    padding-left: 2em;
    justify-content: center;
    margin-top: 1em;
    padding-top: 1em;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10em;
}


.footer-item {
    padding-left: 1em;
    padding-right: 1em;
}
.footer-item a {
    font-size: 0.8em;
}

.logo-caps {
    font-family:"Gothic Caps" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;

    font-size: 3em;
    font-weight: normal;
    vertical-align: middle;
}
  
.logo-smalls {
    font-family:"Old English Text MT" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;

    font-size: 2em;
    font-weight: normal;
    margin-right: 0.25em;
    vertical-align: middle;
}

.address {
    font-size: 0.75em;
    margin-bottom: 1em;
}

#footer a {
    color: slateblue;
    text-decoration: none;
}

#footer a:visited {
    color: slateblue;
    text-decoration: none;
}