.age-validation {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  
  .age-validation .text {
      margin-bottom: 1em;
  }
  
  .age-validation .form {
    margin-bottom: 1em;
  }

  .age-validation .title {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .age-validation .subtitle {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
  
  .age-validation .useraccept {
    margin-bottom: 0.5em;
  }
  
  .age-validation-fields {
    justify-content: center;
  }
  
  .age-validation button {
    margin-top: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 45%;
  }
  
  .age-validation-fields {
    display: flex;
    flex-direction: row;
  }
  
  .age-validation-fields div {
    margin-right: 2em;
    width: 2em;
  }