@media only screen and (max-width: 480px) {
    .header-section-desktop {
        display: none;
    }

    .header-search-desktop {
        display: none;
    }

    #header div.hamburger {
        visibility: hidden;
    }
    
    .header-section-mobile {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .header-search-desktop {
        margin-right: 0em;
    }

    .business-title {
        font-size: 1em;
    }
    
    .business-subname {
        font-size: 0.8em;
    }

    .header-toolbar {
        padding-right: 0em !important;
    }

    .header-section-mobile > button {
        padding-right: 0em;
        padding-left: 0.5em;
    }
}

